<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-img :src="require('@/assets/images/logo.png')" alt="logo" max-width="130px" contain class="ml-auto mr-auto mb-3" />
      <v-card class="auth-card">
        <!-- login form -->
        <v-card-text>
          <v-form @submit.prevent="requestOTP" v-if="!isOTPRequested">
            <v-text-field
              class="mb-3"
              label="Mobile Number"
              placeholder="9876543210"
              :disabled="isOTPRequested"
              v-model="form.mobile"
              required
              :error-messages="errors.mobile"
            />
            <v-text-field
              type="password"
              class="mb-3"
              label="Password"
              placeholder="Password"
              :disabled="isOTPRequested"
              v-model="form.password"
              required
              :error-messages="errors.password"
            />
            <v-btn type="submit"
                   block
                   color="primary"
                   class="mt-3"
            >
              Request for OTP
            </v-btn>
          </v-form>

          <div class="ma-auto position-relative" v-if="isOTPRequested">
            <v-text-field
              v-model="form.otp"
              length="6"
              :disabled="loading"
              placeholder="OTP"
            />
            <v-overlay absolute :value="loading">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-overlay>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar.active" :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar.active = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  data() {
    return {
      snackbar: {
        active: false,
        message: null,
        timeout: -1,
        color: null
      },
      form: {
        mobile: null,
        password: null,
        otp: null,
      },
      errors: {
        mobile: [],
        password: [],
      },
      isOTPVisible: false,
      isOTPRequested: false,
      loading: false,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

    };
  },
  watch: {
    mobile () {
      this.errors.mobile = [];
    },
    password () {
      this.errors.password = [];
    }
  },
  methods: {
    async verifyOTP() {
      this.loading = true;
      try {
        let {data} = await axiosBackend.post('/otp/verify', this.form);

        this.$cookie.set('auth-token', data.token);
        this.$cookie.set('auth-user', JSON.stringify(data.user));

        this.loading = false;
        this.$router.push({name: 'donations'});
      } catch(error) {
        this.loading = false;
        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
        this.snackbar = {
          active: true,
          color: 'error',
          timeout: 5000,
          message: error.response.data.message,
        };
      }
    },
    async requestOTP() {
      try {
        let response = await axiosBackend.post('/otp/request', {mobile: this.form.mobile, password: this.form.password})
        this.snackbar = {
          active: true,
          color: '',
          timeout: -1,
          message: 'OTP is ' + response.data.otp,
        };
        this.isOTPRequested = true;

        setTimeout(() => {
          this.form.otp = response.data.otp;
          this.verifyOTP();
        }, 2000);
      } catch(error) {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
        this.snackbar = {
          active: true,
          color: 'error',
          timeout: 5000,
          message: error.response.data.message,
        };
      }
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
